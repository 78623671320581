<template>
  <div>

    <!-- Modal -->
    <modal :show.sync="modalbannerwarn" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">{{ $t('deletebannerwarn') }}</h4>
      <p>{{ $t('deletebannerwarntxt') }}</p>
      <template slot="footer">
        <div class="left-side">
          <p-button v-on:click="deleteBanner" link>{{ $t('continue_text') }}</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" v-on:click="cancelBannerDeletion" link>{{ $t('cancel') }}</p-button>
        </div>
      </template>
    </modal>

    <!--Stats cards-->
    <div class="row">
      <div class="col-md-10">

        <div class="card" v-for="banner in banners">
          <div class="card-body text-center">
            <img :src="banner.banner" class="adbanner" />
          </div>
          <div class="card-footer">
            <hr/>

            <div class="pull-left">
              <el-tooltip :content="banner.banner_url" :open-delay="100" placement="bottom">
                <p-button class="meine-op-btn" type="icon" link>
                  <i class="fa fa-link fa-2x"></i>
                </p-button>
              </el-tooltip>
            </div>

            <div class="pull-right" v-if="banner.inprogress == false">
              <el-tooltip :content="deletebanner" :open-delay="100" placement="top">
                <p-button class="ml-3 meine-op-btn" type="icon" link v-on:click="deleteBannerWarn(banner)">
                  <i class="fa fa-trash-o fa-2x"></i>
                </p-button>
              </el-tooltip>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
  import {Tooltip} from 'element-ui'
  import { Modal } from 'src/components/UIComponents'
  import VueCookies from 'vue-cookies'

  const user_data = JSON.parse(localStorage.getItem('user-info'));
  //const main_banner_url = "https://webrdp.8topuz.hokocloud.com/static";
  //const main_banner_url = "http://localhost:8081/static";
  const def_banners = [
    { 'banner': "/static/Banner1.jpeg", 'url': 'https://www.hokocloud.com' },
    { 'banner': "/static/Banner2.jpeg", 'url': 'https://www.hokocloud.com' },
    { 'banner': "/static/Banner3.jpeg", 'url': 'https://www.hokocloud.com' }
  ]

  export default {
    components: {
      [Tooltip.name]: Tooltip,
      Modal
    },
    data () {
      return {
        banners: [],
        deletebanner: this.$t('deletebanner'),
        modalbannerwarn: false,
        inprogress: false,
        modalvpswarn: false,
        deletingbanner: null
      }
    },
    methods: {
      deleteBannerWarn(banner) {
        this.modalbannerwarn = true;
        this.deletingbanner = banner;
      },
      cancelBannerDeletion() {
        this.modalbannerwarn = false;
        this.deletingbanner = null;
      },
      loadBanner(resp) {
        let banns = resp.data;
        for(var i=0;i<banns.length;i++) {
          let bannerdata = {
            'id': parseInt(banns[i]['id']),
            'banner': banns[i]['banner_link'],
            'banner_url': banns[i]['banner_url'],
            'inprogress': false,
          }
          this.banners.push(bannerdata);
        }
      },
      deleteBanner() {
        this.modalbannerwarn = false;

        const banner = this.deletingbanner;
        banner.inprogress = true;

        const doneop = resp => {
          this.$toast.info(this.$t('deletedbanner'));
          banner.inprogress = false;
          // Reload Banners
          this.$getBanners().then(this.loadBanners, failop);
        };
        const failop = error => {
          console.log(error);
          banner.inprogress = false;
        };
        this.$deleteBanner(banner).then(doneop, failop);
      }
    },
    mounted() {
      const failop = error => {
        console.log(error);
      };
      this.$getBanners().then(this.loadBanners, failop);
    }
  }

</script>
<style scoped>
  .adbanner {
    position: relative;
    top: 15px;
  }
  .card label {
    font-size: 1em;
  }
  .meine-op-btn:hover {
    background-color: #fff !important;
  }
  .meine-op-btn:hover i.fa-trash-o {
    color: red !important;
  }
  .meine-op-btn:hover i {
    color: #51bcda !important;
  }
</style>
